<template>
  <div class="container">
    <div>
      <div class="top" id="bloc-0">
        <voice-header></voice-header>
      </div>
      <div class="main terms-service">
        <h1>{{ $t('termsOfService.title') }}</h1>
        <p class="effective-date">{{ $t('termsOfService.effectiveDate') }}</p>
        <p>Welcome to TikTok Voice. By accessing or using our website (<a href="https://tiktokvoice.net/" title="TikTok Voice Generator">https://tiktokvoice.net/</a>), you agree to comply with and be bound by the following terms and services. If you do not agree to these terms, please do not use our website.
        </p>
        <h2>1. Services</h2>
        <p>TikTok Voice provides an AI text-to-speech generator that generates various voices.</p>
        <h2>2. Ownership</h2>
        <p>When you download an MP3 file from TikTok Voice, you own the MP3 file. However, you do not have the right to resell the MP3 file.</p>
        <h2>3. Non-Personal Data Collection</h2>
        <p>We use web cookies to collect non-personal data.</p>
        <h2>4. Governing Law</h2>
        <p>These terms and services are governed by the laws of the USA.</p>
        <h2>5. Third-Party Content</h2>
        <p>TikTok Voice is not affiliated with or endorsed by TikTok or ByteDance Ltd. Our website displays content provided by third-party services outside of TikTok. TikTok Voice does not control the content displayed on its website, nor does TikTok or ByteDance approve or endorse it.</p>
        <h2>6. Contact Information</h2>
        <p>For any questions or concerns, please contact us at <a :href="sendmail" title="TikTok Voice generator mail">tiktokaivoicetool@gmail.com</a>.</p>
        <p>By using our website, you acknowledge that you have read and understood these terms and agree to be bound by them.</p>
        <hr />
        <div class="footer-info">
          <p>TikTok Voice <a href="https://tiktokvoice.net" title="TikTok Voice Generator">https://tiktokvoice.net</a></p>
          <p><a :href="sendmail" title="TikTok Voice generator mail">tiktokaivoicetool@gmail.com</a></p>
        </div>
      </div>
      <div>
        <voice-footer></voice-footer>
      </div>
    </div>
  </div>
</template>

<script>
import VoiceHeader from '../components/VoiceHeader.vue';
import { defineAsyncComponent } from 'vue'
const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooter.vue'))

export default {
  name: 'termsOfService',
  head() {
    return {
      'title': this.$i18n.t('txt2voice.title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('txt2voice.description'),
    }
  },
  components: {
    VoiceHeader,
    VoiceFooter
  },
  computed: {
    sendmail() {
      return `mailto:${this.$i18n.t('comm.footer_mail')}`
    }
  }
}
</script>

<style scoped>
.terms-service {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.effective-date {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
}

h2 {
  color: #444;
  margin-top: 30px;
}

p {
  margin-bottom: 15px;
}

a {
  color: #1da1f2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

hr {
  margin: 30px 0;
  border: none;
  border-top: 1px solid #eee;
}

.footer-info {
  text-align: center;
  color: #666;
  font-size: 0.9em;
}
</style>
